import { isMobileBreakpoint } from '../../../../common/js_helpers/dom_helpers';
import get from 'lodash.get';
import hubEvents from '../../../../common/hub_events/hub_events';
import RecommendationController, {
  RECO_STRATEGY_PANEL,
} from '../../../../common/recommendations/recommendation_controller';
import RecommendationHelper from '../../../../common/recommendations/recommendation_helper';

interface RecoPanelElements {
  navBar: HTMLElement;
  parent: HTMLElement;
  toggleButton: HTMLButtonElement;
  toggleLabel: HTMLElement;
  content: HTMLElement;
  tileList: HTMLUListElement;
}

interface RecoPanelCustomLabels {
  expand: string;
  collapse: string;
}

export class RecommendationPanelComponent {
  private readonly EXPANDED_CLASS_NAME: string = 'active';

  private readonly MOBILE_CLASS_NAME: string = 'uf-reco-is-mobile';

  private readonly selectors = {
    content: '#uf-reco-panel-content',
    navBarById: 'uf-top-nav-container',
    parentById: 'uf-reco-panel',
    tileLink: '.uf-tile',
    tileList: '#uf-reco-tiles-list',
    toggleButton: '#uf-reco-panel-toggle',
    toggleLabel: '#uf-reco-panel-toggle-label',
  };

  private dom!: RecoPanelElements;

  private customLabels: RecoPanelCustomLabels = { collapse: '', expand: '' };

  private initialExpandDelay: number = 0;

  private isExpanded: boolean = false;

  private recoController: RecommendationController;

  private recoHelper!: RecommendationHelper;

  public constructor(recommendationController: RecommendationController) {
    this.recoController = recommendationController;

    if (this.setBindings()) {
      this.recoHelper = new RecommendationHelper({
        list: this.dom.tileList,
        parent: this.dom.parent,
      });

      this.initialExpandDelay = get(this.recoHelper.templateOptions, 'panel_delay', 0) * 1000;

      this.init();
    } else {
      this.destroy();
    }
  }

  private setBindings = (): boolean => {
    const navBar = document.getElementById(this.selectors.navBarById) as HTMLElement;
    const parent = document.getElementById(this.selectors.parentById) as HTMLElement;
    if (!parent) return false;

    const toggleButton = parent.querySelector(this.selectors.toggleButton) as HTMLButtonElement;
    const toggleLabel = parent.querySelector(this.selectors.toggleLabel) as HTMLElement;
    const content = parent.querySelector(this.selectors.content) as HTMLElement;
    const tileList = parent.querySelector(this.selectors.tileList) as HTMLUListElement;
    if (!toggleButton || !toggleLabel || !content || !tileList) return false;

    this.dom = {
      content,
      navBar,
      parent,
      tileList,
      toggleButton,
      toggleLabel,
    };

    this.customLabels = {
      collapse: toggleLabel.dataset.customLabelCollapse || '',
      expand: toggleLabel.dataset.customLabelExpand || '',
    };

    return true;
  };

  private init = (): void => {
    if (this.recoController.getStrategy() === RECO_STRATEGY_PANEL) {
      this.setupRecommendations();
    } else {
      this.destroy();
    }
  };

  private setupRecommendations = async (): Promise<void> => {
    const recoResponseBody = await this.recoController.getService().call();

    if (!recoResponseBody || !recoResponseBody.tiles || recoResponseBody.tiles.length === 0) {
      this.destroy();
      return;
    }

    this.recoHelper.renderNewTiles(recoResponseBody.tiles as HTMLElement[]);
    this.setTilesFocusable(false);
    this.updateTopPosition();
    this.bindEvents();
    this.recoHelper.setClass();
    this.recoHelper.show();
    hubEvents.publish('recoItemsLoaded');

    if (!isMobileBreakpoint()) {
      this.startAutoExpandTimer();
    }
  };

  private bindEvents = (): void => {
    this.dom.toggleButton.addEventListener('click', this.handleToggleClick.bind(this));
    hubEvents.subscribe('scroll', this.updateTopPosition);
    hubEvents.subscribe('resize', this.updateTopPosition);
  };

  private unbindEvents = (): void => {
    hubEvents.unsubscribe('scroll', this.updateTopPosition);
    hubEvents.unsubscribe('resize', this.updateTopPosition);
  };

  private handleToggleClick = (): void => {
    this.toggle();

    if (this.isExpanded) {
      this.focusFirstTile();
    }
  };

  private toggle = (): void => {
    this.isExpanded = !this.isExpanded;

    if (this.isExpanded) {
      this.expand();
    } else {
      this.collapse();
    }
  };

  private focusFirstTile = (): void => {
    const firstTileElement = this.dom.tileList.querySelector(
      this.selectors.tileLink,
    ) as HTMLAnchorElement;
    firstTileElement.focus();
  };

  private expand = (): void => {
    this.dom.parent.classList.add(this.EXPANDED_CLASS_NAME);
    this.dom.toggleButton.setAttribute('aria-expanded', 'true');
    this.dom.content.setAttribute('aria-hidden', 'false');
    this.dom.toggleLabel.innerText = this.customLabels.collapse;
    this.setTilesFocusable(true);
    hubEvents.publish('recoPanelOpen');
  };

  private collapse = (): void => {
    this.dom.parent.classList.remove(this.EXPANDED_CLASS_NAME);
    this.dom.toggleButton.setAttribute('aria-expanded', 'false');
    this.dom.toggleLabel.innerText = this.customLabels.expand;
    this.dom.content.setAttribute('aria-hidden', 'true');
    this.setTilesFocusable(false);
  };

  private setTilesFocusable = (focusable: boolean): void => {
    const anchorElements = [
      ...this.dom.content.querySelectorAll(this.selectors.tileLink),
    ] as HTMLAnchorElement[];

    anchorElements.forEach((anchorElement: HTMLAnchorElement) => {
      if (focusable) {
        anchorElement.removeAttribute('tabindex');
      } else {
        anchorElement.setAttribute('tabindex', '-1');
      }
    });
  };

  private updateTopPosition = (): void => {
    if (isMobileBreakpoint() || !this.dom.navBar) {
      this.setTop();
      this.dom.parent.classList.add(this.MOBILE_CLASS_NAME);
      return;
    }

    this.dom.parent.classList.remove(this.MOBILE_CLASS_NAME);

    const panelTopAuto = get(this.recoHelper.templateOptions, 'panel_top_auto', null);
    if (panelTopAuto && this.dom.navBar) {
      this.setTopUnderNavBar();
    }
  };

  private setTop = (top: number = 0): void => {
    this.dom.parent.style.top = top <= 0 ? '' : `${top}px`;
  };

  /**
   * Positions the Reco Panel under the bottom of the Nav Bar, which means it will change
   * as visitor scrolls in the browser. This value can be negative too (means the Nav Bar
   * is outside the viewport).
   */
  private setTopUnderNavBar = (): void => {
    const navBarBottom = this.dom.navBar.getBoundingClientRect().bottom;
    this.setTop(navBarBottom);
  };

  private startAutoExpandTimer = (): void => {
    setTimeout(() => {
      if (!this.isExpanded) {
        this.toggle();
      }
    }, this.initialExpandDelay);
  };

  private destroy = (): void => {
    this.unbindEvents();

    if (this.dom && this.dom.parent && this.dom.parent.parentNode) {
      this.dom.parent.parentNode.removeChild(this.dom.parent);
    }
  };
}

export default RecommendationPanelComponent;
