import apiCaller from '../api_caller';
import get from 'lodash.get';

class RecommendationService {
  private API_ENDPOINT = '/themes/ajax_getRecommendations';

  private TEMPLATE_OPTIONS = get(window, 'uberflip.recommendationOptions.template_data', {});

  private REQUEST_OPTIONS = get(window, 'uberflip.recommendationOptions.api_request_data', {});

  private RECO_ENGINE_TYPE_AI = 1;

  private RECO_ENGINE_TYPE_STREAM = 2;

  private getTypeId = this.REQUEST_OPTIONS.is_reco_ai
    ? this.RECO_ENGINE_TYPE_AI
    : this.RECO_ENGINE_TYPE_STREAM;

  /**
   * The request body to be passed through to the recommendation-api through ajax_getRecommendations call
   * This eslint disable is needed in order to create the request body for recommendation-api
   * The recommendation-api is expecting snake-case not camelcase
   */
  private requestBody: RecoRequestBody = {
    context: {
      collection_id: this.REQUEST_OPTIONS.collection_id,
      hide_date: this.TEMPLATE_OPTIONS.hide_publish_date,
      hub_id: this.REQUEST_OPTIONS.hub_id,
      item_id: this.REQUEST_OPTIONS.item_id,
      url: window.location.href,
      visitor: {
        bombora: {},
        uberflip: {},
      },
      visual: this.TEMPLATE_OPTIONS.override_next
        ? [{ name: 'carousel' }, { name: 'next' }]
        : [{ name: 'panel' }],
    },
    filters: {
      count: this.REQUEST_OPTIONS.max_items_to_display,
      max_age_days: this.REQUEST_OPTIONS.max_item_age,
    },
    rule: {
      id: this.REQUEST_OPTIONS.reco_rule_id,
      type: 'hub',
    },
    type: {
      id: this.getTypeId,
    },
  };

  private requestPromise!: Promise<RecoResponseBody | null>;

  public constructor() {
    if (this.REQUEST_OPTIONS.uuid) {
      this.requestBody.context.visitor.uberflip.uuid = this.REQUEST_OPTIONS.uuid;
    }

    if (window.localStorage && get(window, 'localStorage._ccmaid')) {
      this.requestBody.context.visitor.bombora.id = get(window, 'localStorage._ccmaid');
    }

    if (this.REQUEST_OPTIONS.is_reco_ai) {
      if (this.REQUEST_OPTIONS.ai_source_streams.length > 0) {
        this.requestBody.filters.allowed_collection_ids = this.REQUEST_OPTIONS.ai_source_streams;
      }
    } else {
      this.requestBody.type.source_collection_id = this.REQUEST_OPTIONS.source_collection_id;
    }
  }

  public call = (): Promise<RecoResponseBody | null> => {
    if (!this.requestPromise) {
      this.requestPromise = this.REQUEST_OPTIONS.is_reco_ai
        ? this.awaitBombora().then(() => this.post())
        : this.post();
    }

    return this.requestPromise;
  };

  /**
   * continuously checks to see if bombora information is available
   * once bombora data is available this function will update the reqBody for the recommendation-api
   *
   * @return {promise} returns a promise once resolved
   */
  private awaitBombora = (): Promise<void> => {
    let countdown = 8;

    return new Promise((resolve): void => {
      const checkBomboraReady = () => {
        const bomboraInfo = this.getBomboraInfo();
        if (bomboraInfo && bomboraInfo.us) {
          this.requestBody.context.visitor.bombora.intent_topics = bomboraInfo.us.tp;
          resolve();
        } else if (countdown === 0) {
          resolve();
        } else {
          countdown -= 1;
          setTimeout(checkBomboraReady, 200);
        }
      };

      checkBomboraReady();
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getBomboraInfo = (): { us?: { tp?: any } } | undefined => get(window, '_ml');

  /**
   * makes api call to endpoint ajax_getRecommendations which then makes another call to recommendation_api
   *
   * @return {apiResult} returns an array of tileElements from the apiResult
   */
  public async post(): Promise<RecoResponseBody | null> {
    const response = await apiCaller.post(this.API_ENDPOINT, this.requestBody);
    const tilesHtml: string[] | null = get(response, 'data.response.tiles', []);
    const prevNextItemsHtml: string | null = get(response, 'data.response.prevNextItems', '');

    if (!tilesHtml) return null;
    const tilesDOM = new DOMParser().parseFromString(tilesHtml.join(''), 'text/html');
    const tileElements = Array.from(tilesDOM.body.childNodes) as HTMLElement[];

    let prevNextItemsElement = null;
    if (prevNextItemsHtml) {
      const prevNextItemsDOM = new DOMParser().parseFromString(prevNextItemsHtml, 'text/html');
      prevNextItemsElement = prevNextItemsDOM.body.childNodes[0] as HTMLElement;
    }

    return {
      prevNextItems: prevNextItemsElement,
      tiles: tileElements,
    };
  }
}

export default RecommendationService;
