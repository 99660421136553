/* eslint-disable no-new */
import FormCtaItemGated from '../../tiles/form_cta/helpers/form_cta_gated';
import TileArea from '../../tiles/tile_area';

const initCtaTile = (containerSelector: string) => {
  const container = document.getElementById(containerSelector) as HTMLElement;
  if (container) {
    new TileArea(container).initializeTiles();
  }
};

const initStandaloneCta = () => {
  initCtaTile('uf-gated-cta-wrapper');
  initCtaTile('uf-cta-beside');
  new FormCtaItemGated();
};

export default initStandaloneCta;
