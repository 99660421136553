import hubEvents from '../../../../common/hub_events/hub_events';
import RecommendationController, {
  RECO_STRATEGY_OVERRIDE,
} from '../../../../common/recommendations/recommendation_controller';
import RecommendationHelper from '../../../../common/recommendations/recommendation_helper';

interface PrevNextElements {
  parent: HTMLElement;
  self: HTMLElement;
}

class PrevNextItemsComponent {
  private readonly selectors = {
    nextLink: '.uf-next-link',
    parent: '#uf-item-aside-content',
    self: '#uf-prev-next-items',
  };

  private dom!: PrevNextElements;

  private recoController: RecommendationController;

  private recoHelper!: RecommendationHelper;

  public constructor(recommendationController: RecommendationController) {
    this.recoController = recommendationController;

    if (this.setBindings()) {
      this.recoHelper = new RecommendationHelper({
        parent: this.dom.self,
      });

      this.init();
    }
  }

  private init = async (): Promise<void> => {
    if (this.recoController.getStrategy() === RECO_STRATEGY_OVERRIDE) {
      await this.setupRecommendations();
    }

    this.recoHelper.show();
  };

  private setBindings = (): boolean => {
    const parent = document.querySelector(this.selectors.parent) as HTMLElement;
    if (!parent) return false;

    const self = parent.querySelector(this.selectors.self) as HTMLElement;
    this.dom = { parent, self };
    return true;
  };

  private setupRecommendations = async (): Promise<void> => {
    const recoResponseBody = await this.recoController.getService().call();

    if (!recoResponseBody || !recoResponseBody.prevNextItems) {
      this.recoHelper.show();
      return;
    }

    this.swapNextRecommendation(recoResponseBody.prevNextItems);

    hubEvents.publish('recoPanelOpen');
  };

  private swapNextRecommendation = (prevNextItems: HTMLElement): void => {
    const anchorElement = prevNextItems.querySelector(this.selectors.nextLink) as HTMLAnchorElement;
    if (anchorElement) {
      this.recoHelper.bindClickTracking(anchorElement);
    }

    if (!this.dom.self) {
      const insertBeforeChildElement = this.dom.parent.childNodes[0] || null;
      this.dom.parent.insertBefore(prevNextItems, insertBeforeChildElement);
    } else {
      this.dom.parent.replaceChild(prevNextItems, this.dom.self);
    }
  };
}

export default PrevNextItemsComponent;
