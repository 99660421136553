import { AxiosResponse } from 'axios';
import apiCaller from '../api_caller';
import LocalStorageHelper from '../js_helpers/local_storage_helper';

interface KnownUserServices {
  [maService: string]: 0 | 1;
}

type ItemView = [number, number, number, string];

interface ItemViewsByService {
  [maService: string]: ItemView[];
}

interface PageViewHistory {
  items: ItemView[];
}

class PageViewTracker {
  private readonly knownUserServices: KnownUserServices = window.uberflip.knownUser || [];

  private readonly allTrackedServices: string[] = window.uberflip.integrationsToTrackViews || [];

  // Integrations where visitor had Submitted CTA and now has a tracking ID
  private maServicesUserKnown: string[] = [];

  private maServicesDormant: string[] = [];

  private itemId: number = 0;

  private collectionId: number = 0;

  private completedGatedCta: boolean = false;

  private timestamp: number = window.uberflip.serverTimestamp || Math.floor(Date.now() / 1000);

  public constructor() {
    if (this.setBindings()) {
      this.init();
    }
  }

  private setBindings = (): boolean => {
    const body = document.body as HTMLElement;
    if (!body) return false;

    this.itemId = Number(body.dataset.itemId || 0);
    if (!this.itemId) return false;

    this.maServicesUserKnown = Object.keys(this.knownUserServices).filter(
      (maService: string) => !!this.knownUserServices[maService],
    );

    this.maServicesDormant = this.allTrackedServices.filter(
      (maService: string) => !this.maServicesUserKnown.includes(maService),
    );

    if (!this.maServicesUserKnown.length && !this.maServicesDormant.length) {
      return false;
    }

    this.collectionId = Number(body.dataset.streamId || 0);
    // we can't use !! to convert to boolean since dataset has params in strings (!!"false" gets converted to true)
    this.completedGatedCta = body.dataset.completedGatedCta === 'true';
    return true;
  };

  private init = (): void => {
    this.updateTrackedServices().then(() => {
      this.clearTrackedServices();
      this.storeUntrackedServices();
    });
  };

  private updateTrackedServices = async (): Promise<AxiosResponse | null> => {
    if (!this.maServicesUserKnown.length) {
      return null;
    }

    return apiCaller.post('/themes/ajax_trackPageView', {
      blockingCta: this.completedGatedCta,
      views: this.maServicesUserKnown.reduce(this.serviceItemViewsReducer, {}),
    });
  };

  private serviceItemViewsReducer = (accumulator: ItemViewsByService, maService: string) => {
    const itemViewsByService = { ...accumulator };
    const data = new LocalStorageHelper(maService).get();
    const storedItemViews = data && data.items && Array.isArray(data.items) ? data.items : [];
    const currentItemView = this.getCurrentItemView();

    itemViewsByService[maService] = [...storedItemViews, currentItemView];
    return itemViewsByService;
  };

  private getCurrentItemView = (): ItemView => [
    this.itemId,
    this.collectionId,
    this.timestamp,
    window.location.toString(),
  ];

  private clearTrackedServices = (): void => {
    const data = { items: [] };
    this.maServicesUserKnown.forEach((maService) => new LocalStorageHelper(maService).set(data));
  };

  /**
   * For Integrations where the user is still not known, and therefore is not being
   * tracked, use LocalStorage to record the hub items viewed. If the user activates
   * this Integration later, the item view history will be attached to the CTA.
   */
  private storeUntrackedServices = (): void => {
    if (!this.maServicesDormant.length) {
      return;
    }

    const currentItemView = this.getCurrentItemView();

    this.maServicesDormant.forEach((maService: string) => {
      const maServiceStorage = new LocalStorageHelper(maService);
      const data = maServiceStorage.get();

      // reset history if user has reached the limit of 150 item views
      const reset = !data || !data.items || !Array.isArray(data.items) || data.items.length >= 150;
      const pageViewHistory: PageViewHistory = {
        items: reset ? [currentItemView] : [...data.items, currentItemView],
      };

      maServiceStorage.set(pageViewHistory);
    });
  };
}

export default PageViewTracker;
