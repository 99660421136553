/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { UF_RECO_PANEL_REQUIRED_WIDTH } from '../uf_constants';
import get from 'lodash.get';
import RecommendationService from './recommendation_service';

export const RECO_STRATEGY_PANEL = 'panel';

export const RECO_STRATEGY_OVERRIDE = 'carousel_next_override';

export const RECO_STRATEGY_OFF = 'no_recommendations';

type RecommendationStrategy =
  | typeof RECO_STRATEGY_PANEL
  | typeof RECO_STRATEGY_OVERRIDE
  | typeof RECO_STRATEGY_OFF;

interface RecoCtrlElements {
  itemContainer?: HTMLElement;
}

interface RecoCtrlSelectors {
  itemContainer: string;
}

class RecommendationController {
  private options: RecommendationOptions = get(window, 'uberflip.recommendationOptions', null);

  private strategy: RecommendationStrategy = RECO_STRATEGY_OFF;

  private selectors: RecoCtrlSelectors = {
    itemContainer: '#uf-item-container',
  };

  private dom: RecoCtrlElements = {};

  private recommendationService!: RecommendationService;

  /**
   * 1. if `recommendation_options` is empty:
   *    |-> RelatedItemsCarousel: just bind Slider().
   *    |-> PrevNextItems: do nothing.
   *    |-> RecoPanel: do not render in liquid.
   * 2. if `recommendation_options` is not empty:
   *    |-> a) if `override_next` is false:
   *        |-> RecoPanel(): inject & render.
   *    |-> b) if `override_next` is true:
   *        |-> i) if `enable_panel` is false:
   *            |-> RelatedItems, Prev/Next: inject & render.
   *            |-> RecoPanel: destroy().
   *        |-> ii) if `enable_panel` is true:
   *            |-> o) if `recoPanelFitsScreen()` is true:
   *                |-> RecoPanel(): inject & render.
   *                |-> RelatedItems, Prev/Next: show().
   *            |-> oo) if `recoPanelFitsScreen()` is false:
   *                |-> RecoPanel(): destroy().
   *                |-> RelatedItems, Prev/Next: inject & render.
   */
  public constructor() {
    if (this.options && this.setBindings()) {
      this.pickStrategy();
    }
  }

  private setBindings = (): boolean => {
    const itemContainer = document.querySelector(this.selectors.itemContainer) as HTMLElement;
    if (!itemContainer) return false;

    this.dom = { itemContainer };
    return true;
  };

  private pickStrategy = (): void => {
    const { override_next: overrideEnabled, enable_panel: panelEnabled } =
      this.options.template_data;
    const itemContainerLeftMargin = this.dom.itemContainer!.getBoundingClientRect().left;
    const recoPanelFitsScreen = itemContainerLeftMargin >= UF_RECO_PANEL_REQUIRED_WIDTH;

    if (
      (overrideEnabled && !panelEnabled) ||
      (overrideEnabled && panelEnabled && !recoPanelFitsScreen)
    ) {
      this.strategy = RECO_STRATEGY_OVERRIDE;
    } else {
      this.strategy = RECO_STRATEGY_PANEL;
    }
  };

  public getStrategy = (): RecommendationStrategy => this.strategy;

  public getService = (): RecommendationService => {
    if (!this.recommendationService) {
      this.recommendationService = new RecommendationService();
    }
    return this.recommendationService;
  };
}

export default RecommendationController;
