/* eslint-disable no-console */
/* eslint-disable no-new */
import '../form_cta_tile.scss';
import { exitBrowserFullscreenMode } from '../../../../../common/js_helpers/viewport_helpers';
import FormCtaStorage from './form_cta_storage';
import hubEvents from '../../../../../common/hub_events/hub_events';

const getElementsByClassName = (selector: string): HTMLElement[] => [
  ...(document.getElementsByClassName(selector) as HTMLCollectionOf<HTMLElement>),
];

interface ItemGateElements {
  gatedCtaWrapper: HTMLElement | null;
  cta: HTMLElement | null;
  dismissButton: HTMLElement | null;
  gatedContent: HTMLElement[];
  delayed: HTMLElement[];
}

interface GatedCtaOptions {
  initialDelay: number; // 0 or more
  repeatDelay: number; // -1 or more
}

interface FocusableChildElement {
  element: HTMLElement;
  originalTabindex: string | null;
}

interface GatedContentElement extends FocusableChildElement {
  originalAriaHidden: string | null;
  children: FocusableChildElement[];
}

/**
 * Class representing the Form CTAs that are gated on the item pages
 *
 * The bulk of this class checks for local storage of the CTAs
 * if "Allow Dismissal" is checked in the CTA placement settings and
 * "Not Again for this Session" is selected
 *
 * The data-repeat-delay attribute on the item_page.liquid values are:
 *   -1  is not again for this session
 *    0  is not again for this page view
 *   >0  is not again time frame provided (i.e. 30 seconds)
 *
 */
class FormCtaItemGated {
  private readonly selectors = {
    ctaClass: '.uf-gated-cta',
    delayClass: 'uf-gated-delayed',
    focusable:
      "a[href]:not([tabindex='-1']), area[href]:not([tabindex='-1']), input:not([disabled]):not([tabindex='-1']), select:not([disabled]):not([tabindex='-1']), textarea:not([disabled]):not([tabindex='-1']), button:not([disabled]):not([tabindex='-1']), iframe:not([tabindex='-1']), [tabindex]:not([tabindex='-1']), [contentEditable=true]:not([tabindex='-1']), uf-flipbook:not([tabindex='-1'])",
    gatedClass: 'uf-gated-content',
    gatedCloseId: 'uf-gated-cta-close',
    gatedCtaWrapperId: 'uf-gated-cta-wrapper',
    hiddenClass: 'uf-hidden',
    isGatedClass: 'uf-is-gated',
    mainContentId: 'main-content',
    visibleCtaPanelClass: '.uf-cta-panel:not(.uf-hidden)',
  };

  private dom!: ItemGateElements;

  private data!: GatedCtaOptions;

  private storage!: FormCtaStorage;

  private isSession!: boolean;

  private isDelayed!: boolean;

  private isRepeatShow!: boolean;

  private showSuccess!: boolean;

  private gatedElements: GatedContentElement[] = [];

  private disableChildrenTimer: ReturnType<typeof setTimeout> | null = null;

  private disableChildrenLateTimer: ReturnType<typeof setTimeout> | null = null;

  public constructor() {
    if (this.setBindings()) {
      this.init();
    }
  }

  private setBindings(): boolean {
    const gatedCtaWrapper = document.getElementById(this.selectors.gatedCtaWrapperId);
    if (!gatedCtaWrapper) return false;

    this.dom = {
      cta: gatedCtaWrapper.querySelector(this.selectors.ctaClass) as HTMLElement,
      delayed: getElementsByClassName(this.selectors.delayClass),
      dismissButton: document.getElementById(this.selectors.gatedCloseId) as HTMLButtonElement,
      gatedContent: getElementsByClassName(this.selectors.gatedClass),
      gatedCtaWrapper,
    };

    if (!this.dom.cta) return false;

    const dataInitDelay = this.dom.cta.dataset.initDelay || '0';
    const dataRepeatDelay = this.dom.cta.dataset.repeatDelay || '0';

    this.data = {
      initialDelay: parseInt(dataInitDelay, 10),
      repeatDelay: parseInt(dataRepeatDelay, 10),
    };

    this.isSession = this.data.repeatDelay < 0;
    this.isDelayed = this.data.initialDelay > 0;
    this.isRepeatShow = this.data.repeatDelay > 0;
    this.showSuccess = this.dom.cta.getAttribute('data-show-success') === 'true';

    if (this.isSession) {
      this.storage = new FormCtaStorage(this.dom.cta);
    }

    return true;
  }

  private init(): void {
    this.bindEvents();

    if (this.showSuccess) {
      this.hide();
    } else if (this.isSession) {
      this.initSession();
    } else if (this.isDelayed) {
      this.show(this.data.initialDelay);
    } else {
      this.syncDisableChildElements();
    }
  }

  private bindEvents = (): void => {
    hubEvents.subscribe('ctaFormSubmitSuccess', this.handleSubmitSuccess as EventListener);

    if (this.dom.dismissButton && this.dom.gatedCtaWrapper) {
      this.dom.dismissButton.addEventListener('click', this.hide);
      this.dom.gatedCtaWrapper.addEventListener('keyup', this.checkForEscKey);
    }
  };

  private handleSubmitSuccess = (event: CustomEvent): void => {
    const isGated = event.detail.caller.dataset.gatedCta === 'true';
    if (isGated) {
      this.isRepeatShow = false;
      this.hide();
    }
  };

  private initSession = (): void => {
    const isNotDismissed = this.storage.showForPlacementId();
    if (isNotDismissed) {
      this.show(this.data.initialDelay);
    }
  };

  private show = (delaySeconds = 0): void => {
    if (delaySeconds === 0) {
      this.applyShow();
    } else {
      setTimeout(() => {
        this.removeClasses(this.dom.delayed, this.selectors.delayClass);
        this.applyShow();
        this.focusFirstElementInGatedCta();
      }, delaySeconds * 1000);
    }
  };

  private applyShow = (): void => {
    if (!this.dom.gatedCtaWrapper) return;

    exitBrowserFullscreenMode();

    this.dom.gatedCtaWrapper.classList.remove(this.selectors.hiddenClass);

    this.addClasses(this.dom.gatedContent, this.selectors.isGatedClass);
    this.syncDisableChildElements();
  };

  /*
   * When Gated CTA appears after a delay, the tab-focus could become trapped
   * within Flipbooks or other embedded content. So when the Gated CTA appears,
   * set the tab-focus on the first focusable element within the Gated CTA.
   */
  private focusFirstElementInGatedCta = (): void => {
    if (!this.dom.cta) return;

    const panelElement = this.dom.cta.querySelector(this.selectors.visibleCtaPanelClass);
    if (!panelElement) return;

    const focusableElement = panelElement.querySelector(this.selectors.focusable) as HTMLElement;
    if (!focusableElement) return;

    focusableElement.focus();
  };

  /*
   * Need to trigger with a delay, because some content is async and will add
   * elements to the Hub Item page at a later time (eg. Flipbook content),
   * meaning its elements would be missing `tabindex="-1"`.
   */
  private syncDisableChildElements = (): void => {
    this.disableChildElements();

    this.disableChildrenTimer = setTimeout(() => {
      this.disableChildElements();
      this.disableChildrenTimer = null;
    }, 1000);

    this.disableChildrenLateTimer = setTimeout(() => {
      this.disableChildElements();
      this.disableChildrenLateTimer = null;
    }, 3000);
  };

  /*
   * Parent elements must have `aria-hidden="true"` so that a screen reader will
   * skip the content, until the Gated CTA is dismissed. Must also have `tabindex="-1"`
   * so that eg. iframe/video elements do not receive tab-focus.
   *
   * Will also add `tabindex="-1"` on all focusable child elements to prevent them
   * from getting tab-focus.
   *
   * We track reference to all elements in memory so that we can revert their original
   * attribute value when the Gated CTA is dismissed.
   *
   * Note: this function is called multiple times, because new children can be added
   * asynchronously after page load, so we need to disable them. But parents should
   * only be added to our memory-list on the first iteration. Children will only be
   * added if they have not already been added to the `parent.children` list.
   */
  private disableChildElements = (): void => {
    // add reference to parent elements
    if (!this.gatedElements.length) {
      this.dom.gatedContent.forEach((parentElement) => {
        this.gatedElements.push({
          children: [],
          element: parentElement,
          originalAriaHidden: parentElement.getAttribute('aria-hidden'),
          originalTabindex: parentElement.getAttribute('tabindex'),
        });
      });
    }

    // add reference to child elements
    this.gatedElements.forEach((parent) => {
      const childElements = [
        ...(parent.element.querySelectorAll(this.selectors.focusable) as NodeListOf<HTMLElement>),
      ];

      childElements.forEach((childElement) => {
        const alreadyTracked = !!childElement.dataset.gatedCtaTracked;
        if (!alreadyTracked) {
          parent.children.push({
            element: childElement,
            originalTabindex: childElement.getAttribute('tabindex'),
          });
          childElement.setAttribute('data-gated-cta-tracked', 'true');
        }
      });
    });

    // apply disabled state
    this.gatedElements.forEach((parent) => {
      parent.element.setAttribute('tabindex', '-1');
      parent.element.setAttribute('aria-hidden', 'true');
      parent.children.forEach((child) => child.element.setAttribute('tabindex', '-1'));
    });
  };

  private checkForEscKey = (event: KeyboardEvent) => {
    const { key } = event;

    if (key === 'Esc' || key === 'Escape') {
      this.hide();
    }
  };

  private hide = (): void => {
    this.revertChildElements();
    this.removeClasses(this.dom.gatedContent, this.selectors.isGatedClass);
    this.skipToMainContent();

    if (!this.dom.gatedCtaWrapper) return;
    this.dom.gatedCtaWrapper.classList.add(this.selectors.hiddenClass);

    // only show repeatedly if it's not a success panel
    if (this.isRepeatShow && !this.showSuccess) {
      this.show(this.data.repeatDelay);
    } else if (this.isSession) {
      this.storage.setPlacement();
    }
  };

  /*
   * When the Gated CTA is dismissed, make all the blurred elements tabable and
   * readable again. Revert each element `aria-hidden` and `tabindex` attribute to
   * the original value (or remove it altogether).
   */
  private revertChildElements = (): void => {
    // clear timers that may still be outstanding
    if (this.disableChildrenTimer) {
      clearTimeout(this.disableChildrenTimer);
      this.disableChildrenTimer = null;
    }

    if (this.disableChildrenLateTimer) {
      clearTimeout(this.disableChildrenLateTimer);
      this.disableChildrenLateTimer = null;
    }

    // revert all parent and child attributes to original values
    this.gatedElements.forEach((parent) => {
      if (parent.originalAriaHidden !== null) {
        parent.element.setAttribute('aria-hidden', parent.originalAriaHidden);
      } else {
        parent.element.removeAttribute('aria-hidden');
      }

      if (parent.originalTabindex !== null) {
        parent.element.setAttribute('tabindex', parent.originalTabindex);
      } else {
        parent.element.removeAttribute('tabindex');
      }

      parent.children.forEach((child) => {
        if (child.originalTabindex !== null) {
          child.element.setAttribute('tabindex', child.originalTabindex);
        } else {
          child.element.removeAttribute('tabindex');
        }
      });
    });
  };

  private addClasses = (elements: HTMLElement[], className: string): void => {
    [...elements].forEach((element: Element) => element.classList.add(className));
  };

  private removeClasses = (elements: HTMLElement[], className: string): void => {
    [...elements].forEach((element: Element) => element.classList.remove(className));
  };

  /*
   * When Gated CTA is dismissed, the tab-focus could become trapped within the hidden
   * CTA. So navigate the user to `<main id="main-content" ...>` element to continue
   * tabbing from there.
   */
  private skipToMainContent = (): void => {
    window.location.hash = this.selectors.mainContentId;
  };
}

export default FormCtaItemGated;
