/* eslint-disable no-new */
import '../../../common/privacy/privacy';
import './item_page.scss';
import {
  hubEventLoadDispatch,
  sharedHubEventsInit,
} from '../../../common/hub_events/shared_hub_events';
import bodyTopInit from '../../components/body_top/body_top';
import Flipbook from '../../components/item_page/content/docs/flipbook/flipbook';
import footerInit from '../../components/footer/footer';
import initStandaloneCta from '../../components/item_page/standalone_cta/standalone_cta';
import PageViewTracker from '../../../common/hub_tracking/page_view_tracker';
import PreviewModeComponent from '../../components/item_page/preview_mode/preview_mode';
import PrevNextItemsComponent from '../../components/item_page/prev_next_items/prev_next_items';
import RecommendationController from '../../../common/recommendations/recommendation_controller';
import RecommendationPanelComponent from '../../components/item_page/recommendation_panel/recommendation_panel';
import RecoViewHistory from '../../../common/recommendations/reco_view_history';
import RelatedItemsComponent from '../../components/item_page/related_items/related_items';

const setupRecommendationComponents = (): void => {
  const recoController = new RecommendationController();

  new PrevNextItemsComponent(recoController);
  new RelatedItemsComponent(recoController);
  new RecommendationPanelComponent(recoController);
};

const hidePublishedDate = (): void => {
  const recoViewHistory = new RecoViewHistory();
  const { recoItemId } = recoViewHistory.get();
  const hideDate = recoViewHistory.getHideDate();

  if (hideDate && recoItemId) {
    const bodyElement = document.querySelector('body');
    const itemId = +((bodyElement && bodyElement.getAttribute('data-item-id')) || 0);

    if (+recoItemId === itemId) {
      const publishedDate = document.querySelector<HTMLElement>('.uf-datetime');

      if (publishedDate) {
        publishedDate.style.display = 'none';
      }
    }

    recoViewHistory.clearHideDate();
  }
};

const main = (): void => {
  sharedHubEventsInit();
  bodyTopInit();
  footerInit();
  new Flipbook();
  initStandaloneCta();
  new PreviewModeComponent();
  setupRecommendationComponents();
  new PageViewTracker();
  hubEventLoadDispatch();
};

document.addEventListener('DOMContentLoaded', hidePublishedDate);
document.addEventListener('DOMContentLoaded', main);
