import debounce from 'lodash.debounce';
import get from 'lodash.get';
import hubEvents from '../../../../common/hub_events/hub_events';
import ScrollToComponent from './scrollto_polyfill';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    msCrypto: any;
  }
}

export interface SliderElements {
  parent: HTMLElement;
  header: HTMLElement;
  control: {
    previous: HTMLButtonElement;
    next: HTMLButtonElement;
  };
  track: HTMLElement;
  list: HTMLElement;
}

interface SliderState {
  trackWidth: number;
  listWidth: number;
  listItemsTotal: number;
  listItemsPerPage: number;
  offsetPerPage: number;
  lastPage: number;
  currentPage: number;
}

export class SliderComponent {
  private readonly isIE11: boolean = !!window.msCrypto;

  private readonly HIDE_CLASS_NAME = 'uf-hidden';

  private dom: SliderElements;

  private state!: SliderState;

  private scrollTo!: ScrollToComponent;

  public constructor(sliderElements: SliderElements) {
    this.dom = sliderElements;

    if (this.setBindings()) {
      this.init();
    }
  }

  private setBindings(): boolean {
    const missingRequiredFields =
      !this.dom.parent ||
      !this.dom.header ||
      !this.dom.control.previous ||
      !this.dom.control.next ||
      !this.dom.track ||
      !this.dom.list;
    if (missingRequiredFields) {
      return false;
    }

    this.scrollTo = new ScrollToComponent(this.dom.track);
    return true;
  }

  private handleTrackScroll = debounce(() => this.updateCurrentPage());

  private init(): void {
    this.rebuild();
    this.dom.control.next.addEventListener('click', this.setNextPage);
    this.dom.control.previous.addEventListener('click', this.setPreviousPage);
    this.dom.track.addEventListener('scroll', this.handleTrackScroll);
    hubEvents.subscribe('resize', this.resize);
  }

  private hideElement = (element: HTMLElement): void => element.classList.add(this.HIDE_CLASS_NAME);

  private showElement = (element: HTMLElement): void =>
    element.classList.remove(this.HIDE_CLASS_NAME);

  /*
   * Rebuild can be used if <li> items are changed (added or removed) to recalculate
   * and reset the slider (controls, current page, etc).
   */
  public rebuild = (): void => {
    this.recalculate();
    this.updateButtons();
    this.setPage(1);
  };

  /*
   * Recalculate is used when the browser is resized to determine all the dimensions
   * required for the controls to increment the correct amount, including checking
   * of any changes to <li> item margins (mobile devices have smaller margins).
   *
   * Notice: also handles with hide and show of the slider, depending on the number
   * of <li> items in the slider.
   */
  private recalculate = (): void => {
    const listItemElements: HTMLElement[] = [
      ...(this.dom.list.getElementsByTagName('li') as HTMLCollectionOf<HTMLElement>),
    ];
    const listItemsTotal: number = listItemElements.length;

    if (!listItemsTotal) {
      this.hideElement(this.dom.parent);
      return;
    }

    this.showElement(this.dom.parent);

    const trackWidth: number = this.dom.track.getBoundingClientRect().width;
    const listWidth: number = this.getListWidth(listItemElements);

    if (this.isIE11) {
      this.dom.list.style.width = `${listWidth}px`;
    }

    const listItemStyle = window.getComputedStyle(listItemElements[0]);
    const listItemWidth = listItemElements[0].getBoundingClientRect().width;
    const marginBetween = parseFloat(listItemStyle.getPropertyValue('margin-right'));
    const listItemOuterWidth = listItemWidth + marginBetween;

    const listItemsPerPage: number = this.getItemsFitPerPage(
      trackWidth,
      listItemWidth,
      listItemOuterWidth,
    );
    const offsetPerPage = listItemsPerPage * listItemOuterWidth;
    const lastPage = listItemsTotal > 1 ? Math.ceil(listWidth / offsetPerPage) : 1;
    const currentPage = get(this.state, 'currentPage', 1);

    this.state = {
      currentPage,
      lastPage,
      listItemsPerPage,
      listItemsTotal,
      listWidth,
      offsetPerPage,
      trackWidth,
    };
  };

  private getListWidth = (listItemElements: HTMLElement[]): number => {
    if (!this.isIE11) {
      return this.dom.list.getBoundingClientRect().width;
    }

    return listItemElements
      .map((listItemElement: HTMLElement) => {
        const listItemWidth = listItemElement.getBoundingClientRect().width;
        const listItemStyle = window.getComputedStyle(listItemElement);
        const marginLeft = parseFloat(listItemStyle.getPropertyValue('margin-left'));
        const marginRight = parseFloat(listItemStyle.getPropertyValue('margin-right'));
        return listItemWidth + marginLeft + marginRight;
      })
      .reduce((listWidth, listItemWidth) => listWidth + listItemWidth);
  };

  /*
   * Calculate how many List Item elements can fit within the Slider Track, but ignore the
   * margin-right amount on the last item. Because the full list item could be visible,
   * even though its `margin-right` did not fit into view.
   */
  private getItemsFitPerPage = (
    trackWidth: number,
    itemWidth: number,
    itemOuterWidth: number,
  ): number => {
    if (trackWidth > itemOuterWidth) {
      const limit = Math.ceil(trackWidth / itemWidth);

      for (let i = 1; i < limit; i += 1) {
        const totalItemsWidth = i * itemOuterWidth + itemWidth;

        if (totalItemsWidth > trackWidth) {
          return 1 + (i - 1);
        }
        if (totalItemsWidth === trackWidth) {
          return 1 + i;
        }
      }
    }
    return 1;
  };

  private setPage = (page: number): void => {
    if (page < 1) {
      this.state.currentPage = 1;
    } else if (page > this.state.lastPage) {
      this.state.currentPage = this.state.lastPage;
    } else {
      this.state.currentPage = page;
    }

    const leftOffset = this.getScrollLeftOffset(this.state.currentPage);
    this.scrollTo.left(leftOffset);
  };

  private getScrollLeftOffset = (page: number): number => {
    if (page === 1) {
      return 0;
    }

    if (page === this.state.lastPage) {
      return this.state.listWidth - this.state.trackWidth;
    }

    const pageMultiplier = page - 1;
    return pageMultiplier * this.state.offsetPerPage;
  };

  private resize = (): void => {
    this.recalculate();
    this.updateCurrentPage();
    this.setPage(this.state.currentPage);
  };

  private setNextPage = (): void => this.setPage(this.state.currentPage + 1);

  private setPreviousPage = (): void => this.setPage(this.state.currentPage - 1);

  /*
   * When user scrolls through Slider Track manually, calculate the Current Page based on
   * how far the user has scrolled, and update the Current Page number in state.
   */
  private updateCurrentPage = (): void => {
    this.state.currentPage = this.getCurrentPageByScrollPosition();
    this.updateButtons();
  };

  private getCurrentPageByScrollPosition = (): number => {
    const currentScrollPosition = this.dom.track.scrollLeft;

    for (let page = 1; page <= this.state.lastPage; page += 1) {
      const pageOffset = this.getScrollLeftOffset(page);
      if (pageOffset > currentScrollPosition) {
        return page - 1;
      }
      if (pageOffset === currentScrollPosition) {
        return page;
      }
    }
    return 1;
  };

  private updateButtons = (): void => {
    const currentScrollPosition = this.dom.track.scrollLeft;
    const lastScrollPosition = this.getScrollLeftOffset(this.state.lastPage);

    // previous button: disable or enable
    if (currentScrollPosition === 0) {
      this.disableElement(this.dom.control.previous);
    } else {
      this.enableElement(this.dom.control.previous);
    }

    // next button: disable or enable
    if (currentScrollPosition === lastScrollPosition) {
      this.disableElement(this.dom.control.next);
    } else {
      this.enableElement(this.dom.control.next);
    }

    // hide controls if both buttons are not usable
    if (this.state.lastPage === 1) {
      this.hideElement(this.dom.control.previous);
      this.hideElement(this.dom.control.next);
    } else {
      this.showElement(this.dom.control.previous);
      this.showElement(this.dom.control.next);
    }
  };

  private disableElement = (element: HTMLElement): void =>
    element.setAttribute('disabled', 'disabled');

  private enableElement = (element: HTMLElement): void => element.removeAttribute('disabled');
}
