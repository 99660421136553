/**
 * Preview Mode:
 *
 * On a Hub Item page, the Preview Mode option will limit the length of a long
 * article (based on browser viewport height), and will display a "Continue reading..."
 * button.
 *
 * When a visitor clicks the button, the full article will be displayed, and the
 * button will be hidden.
 *
 */

/* eslint-disable @typescript-eslint/no-non-null-assertion */
interface PreviewModeSelectors {
  articleById: string;
  articleEntryContent: string;
  readMore: string;
  readMoreButton: string;
}

interface PreviewModeElements {
  article: HTMLElement;
  articleEntryContent?: HTMLElement;
  readMore?: HTMLElement;
  readMoreButton?: HTMLButtonElement;
}

class PreviewModeComponent {
  // It's pointless to show a "Read more" button for a single line of overflow text!
  // Overflow content must exceed minimum truncate height (px) to activate Preview Mode.
  private MINIMUM_TRUNCATE_HEIGHT: number = 100;

  private READ_MORE_HEIGHT: number = 140;

  private ACTIVE_CLASS_NAME: string = 'uf-preview-mode';

  private defaultSelectors: PreviewModeSelectors = {
    articleById: 'uf-article',
    articleEntryContent: '#uf-item-blog-content',
    readMore: '#uf-item-read-more',
    readMoreButton: '#uf-item-read-more-button',
  };

  private selectors: PreviewModeSelectors;

  private dom!: PreviewModeElements;

  private originalMaxHeight: string | null = null;

  public constructor(customSelectors?: PreviewModeSelectors) {
    this.selectors = Object.assign({}, this.defaultSelectors, customSelectors);

    if (this.setBindings()) {
      this.init();
    }
  }

  private setBindings(): boolean {
    const article = document.getElementById(this.selectors.articleById) as HTMLElement;
    if (!article) {
      return false;
    }

    this.dom = {
      article,
      articleEntryContent: article.querySelector(this.selectors.articleEntryContent) as HTMLElement,
      readMore: article.querySelector(this.selectors.readMore) as HTMLElement,
      readMoreButton: article.querySelector(this.selectors.readMoreButton) as HTMLButtonElement,
    };

    const missingRequiredElements =
      !this.dom.articleEntryContent || !this.dom.readMore || !this.dom.readMoreButton;
    if (missingRequiredElements) {
      return false;
    }

    return true;
  }

  private init(): void {
    const maxHeight = this.getMaxHeight();

    if (maxHeight) {
      this.activate(maxHeight);
    } else {
      this.destroy();
    }
  }

  /**
   * Content will only switch into Preview Mode when the article length exceeds
   * the browser viewport bottom by at least READ_MORE_HEIGHT (100px).
   *
   * If the Hub Item also includes a Flipbook, Slideshow, Video, etc -- then
   * "Continue Reading..." button must completely clear the embedded content,
   * so that it never covers the embedded content.
   *
   * If the article is very short, even if it exceeds the viewport bottom on a
   * small screen (like a smartphone), we won't truncate the content because
   * that would be poor user experience (to have a button that uncovers only
   * one or two lines of text).
   */
  private getMaxHeight(): number | null {
    const windowScrollY = window.scrollY || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;

    const articleTop = this.dom.article.getBoundingClientRect().top + windowScrollY;

    const entryContentClientRect = this.dom.articleEntryContent!.getBoundingClientRect();
    const entryContentHeight = Math.round(entryContentClientRect.height);
    const entryContentTop = entryContentClientRect.top + windowScrollY;
    const contentBottomOffset = entryContentClientRect.bottom;

    // calculate exceedViewportBottom using articleEntryContent element
    const exceedViewportBottom = contentBottomOffset - this.MINIMUM_TRUNCATE_HEIGHT >= windowHeight;

    // don't truncate when there is barely any content
    const minimumContentHeight = this.READ_MORE_HEIGHT + this.MINIMUM_TRUNCATE_HEIGHT;
    const adequateContent = entryContentHeight > minimumContentHeight;

    if (adequateContent && exceedViewportBottom) {
      // calculate maxHeight, but to apply on article element!
      let maxHeight = windowScrollY + windowHeight - articleTop;

      // adjust button position when Hub Item has a Flipbook, Slideshow, Video, etc.
      // include READ_MORE_HEIGHT so the button won't overlap the embedded content.
      const articleBottom = articleTop + maxHeight;
      const entryContentBumpedByEmbed = articleBottom < entryContentTop + this.READ_MORE_HEIGHT;
      if (entryContentBumpedByEmbed) {
        maxHeight = entryContentTop - articleTop + this.READ_MORE_HEIGHT;
      }

      return maxHeight;
    }
    return null;
  }

  private activate(maxHeight: number): void {
    this.originalMaxHeight = this.dom.article.style.maxHeight;
    this.dom.article.style.maxHeight = `${maxHeight}px`;
    this.dom.article.classList.add(this.ACTIVE_CLASS_NAME);
    this.dom.readMoreButton!.addEventListener('click', this.dismiss);
  }

  private dismiss = (): void => {
    this.dom.article.style.maxHeight = this.originalMaxHeight || '';
    this.dom.article.classList.remove(this.ACTIVE_CLASS_NAME);
    this.destroy();
  };

  private destroy(): void {
    this.dom.readMore!.parentNode!.removeChild(this.dom.readMore!);
  }
}

export default PreviewModeComponent;
