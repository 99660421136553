import {
  callParentIFrame,
  isEmbedFrame,
} from '../../../../../../common/js_helpers/embed_frame_helpers';
import {
  enterBrowserFullscreenMode,
  exitBrowserFullscreenMode,
} from '../../../../../../common/js_helpers/viewport_helpers';
import { MESSAGE_TYPES } from '../../../../../../common/uf_constants';
import hubEvents from '../../../../../../common/hub_events/hub_events';

declare global {
  interface Window {
    parentIFrame: ParentIFrame;
  }
}

interface FlipbookElements {
  container: HTMLElement;
  iframe: HTMLIFrameElement;
  expandButtons: HTMLButtonElement[];
  closeButton: HTMLButtonElement;
  downloadLink: HTMLAnchorElement | null;
}

class Flipbook {
  private readonly HUB_ITEM_FLIPBOOK = 'uf-page-type-uberflip';

  private readonly EVENT_TYPE_PAGE_VIEW = 'view_flipbook_pages';

  private readonly FULLSCREEN_CLASS_NAME = 'uf-fullscreen-flipbook';

  private readonly NO_SCROLL_CLASS_NAME = 'no-scroll';

  private readonly WAIT_FOR_FULLSCREEN_ANIMATION: number = 200;

  private readonly isFlipbookPage = document.body.classList.contains(this.HUB_ITEM_FLIPBOOK);

  private readonly flipbookId: string = document.body.dataset.itemFlipbookid || '';

  private readonly isEmbedFrame: boolean = isEmbedFrame();

  private isFullscreen: boolean = false;

  private previousScrollTop: number = 0;

  private currentScrollTop: number = 0;

  private flipbookOriginUrl: string = '';

  private readonly selectors = {
    closeButton: '.uf-close',
    containerById: 'uf-flipbook',
    downloadLink: '.uf-download',
    expandButtons: '.uf-expand',
  };

  private dom!: FlipbookElements;

  public constructor() {
    if (!this.isFlipbookPage) return;

    if (this.setBindings()) {
      this.init();
    }
  }

  private init = (): void => {
    this.bindEvents();
    this.checkStartInFullscreenMode();
    hubEvents.subscribe('scroll', this.handleScroll);
  };

  private handleScroll = (event: Event): void => {
    if (this.isEmbedFrame) {
      const { detail } = event as CustomEvent;
      this.currentScrollTop = typeof detail === 'object' ? detail.scrollTop : 0;
    } else {
      this.currentScrollTop = window.scrollY || document.documentElement.scrollTop;
    }
  };

  private setBindings = (): boolean => {
    const container = document.getElementById(this.selectors.containerById) as HTMLElement;
    const iframe = container.querySelector('iframe') as HTMLIFrameElement;
    if (!container || !iframe) return false;

    const expandButtons = [
      ...container.querySelectorAll(this.selectors.expandButtons),
    ] as HTMLButtonElement[];
    const closeButton = container.querySelector(this.selectors.closeButton) as HTMLButtonElement;
    if (!expandButtons.length || !closeButton) {
      return false;
    }

    const downloadLink = container.querySelector(this.selectors.downloadLink) as HTMLAnchorElement;
    this.dom = { closeButton, container, downloadLink, expandButtons, iframe };

    this.bindOriginUrl();

    return true;
  };

  private bindOriginUrl = (): void => {
    const flipbookUrl = this.dom.iframe.getAttribute('src') || '';
    const isProtocolMissing = !!(flipbookUrl && /^\//.test(flipbookUrl));
    const prefix = isProtocolMissing ? window.location.protocol : '';

    try {
      this.flipbookOriginUrl = new URL(`${prefix}${flipbookUrl}`).origin;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Error getting flipbook origin: ${(e as Error).message}`);
    }
  };

  private bindEvents = (): void => {
    this.dom.expandButtons.forEach((buttonElement) =>
      buttonElement.addEventListener('click', this.enterFullscreenMode),
    );

    this.dom.closeButton.addEventListener('click', this.exitFullscreenMode);

    if (this.dom.downloadLink) {
      this.dom.downloadLink.addEventListener('click', this.downloadFlipbook);
    }

    window.addEventListener('message', this.handlePostMessage);
  };

  /**
   * Listen for `window.postMessage` events that are being sent from the Flipbook
   * iFrame window.
   *
   * This listener will receive all postMessages from the UF app, so we need to
   * skip all the messages that are not related to Flipbooks (eg. iFrameResizer
   * messages).
   *
   * The events are used for tracking Flipbook activity in UFA.
   *
   * @param event
   */
  private handlePostMessage = (event: MessageEvent): void => {
    const { origin: messageOriginUrl } = event;
    const { type: messageType, flipbookId } = event.data;

    if (messageType !== MESSAGE_TYPES.FLIPBOOK_EVENT) return;
    if (messageOriginUrl !== this.flipbookOriginUrl) return;
    if (String(flipbookId) !== this.flipbookId) return;

    const { eventType } = event.data;

    // Publish event when Flipbook page is flipped:
    if (eventType === this.EVENT_TYPE_PAGE_VIEW) {
      const { pageIds, pageNumbers } = event.data;

      if (!Array.isArray(pageIds) || !Array.isArray(pageNumbers)) return;

      pageIds.forEach((flipbookPageId: string, index: number) => {
        hubEvents.publish('flipbookPageView', {
          detail: {
            flipbookId,
            flipbookPageId,
            pageNumber: pageNumbers[index],
          },
        });
      });
    }
  };

  private checkStartInFullscreenMode = (): void => {
    if (this.dom.container.classList.contains(this.FULLSCREEN_CLASS_NAME)) {
      this.enterFullscreenMode();
    }
  };

  private enterFullscreenMode = (): void => {
    if (this.isFullscreen) return;

    this.previousScrollTop = this.currentScrollTop;
    this.isFullscreen = true;
    this.dom.container.classList.add(this.FULLSCREEN_CLASS_NAME);
    document.body.classList.add(this.NO_SCROLL_CLASS_NAME);
    window.addEventListener('keydown', this.handleKeyPressed);
    document.addEventListener('fullscreenchange', this.handleFullscreenChange);
    enterBrowserFullscreenMode(this.dom.container);
    this.updateEmbedFrameFullscreen(1);
  };

  private exitFullscreenMode = (): void => {
    if (!this.isFullscreen) return;

    this.isFullscreen = false;
    this.dom.container.classList.remove(this.FULLSCREEN_CLASS_NAME);
    document.body.classList.remove(this.NO_SCROLL_CLASS_NAME);
    window.removeEventListener('keydown', this.handleKeyPressed);
    document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
    exitBrowserFullscreenMode();
    this.updateEmbedFrameFullscreen(0);
    this.revertPreviousScrollTop();
  };

  private handleKeyPressed = (keyEvent: KeyboardEvent): void => {
    if (keyEvent.key === 'Escape' || keyEvent.key === 'Esc') {
      this.exitFullscreenMode();
    }
  };

  private handleFullscreenChange = (): void => {
    if (!document.fullscreenElement) {
      this.exitFullscreenMode();
    }
  };

  private revertPreviousScrollTop = (): void => {
    const { previousScrollTop } = this;
    setTimeout(() => {
      if (this.isEmbedFrame) {
        callParentIFrame(() => window.parentIFrame.scroll(previousScrollTop));
      } else {
        window.scroll(0, previousScrollTop);
      }
      this.previousScrollTop = 0;
    }, this.WAIT_FOR_FULLSCREEN_ANIMATION);
  };

  private updateEmbedFrameFullscreen = (isFullscreen: 0 | 1): void => {
    if (this.isEmbedFrame) {
      callParentIFrame(() => window.parentIFrame.fullscreen(isFullscreen));
    }
  };

  private downloadFlipbook = (): void =>
    hubEvents.publish('flipbookDownload', {
      detail: { flipbookId: this.flipbookId },
    });
}

export default Flipbook;
