/* eslint-disable @typescript-eslint/no-non-null-assertion */
import get from 'lodash.get';
import RecoViewHistory from './reco_view_history';
import TileArea from '../../default/components/tiles/tile_area';

interface RecoHelperElements {
  parent?: HTMLElement;
  list?: HTMLElement;
}

class RecommendationHelper {
  private readonly HIDDEN_CLASS_NAME: string = 'uf-hidden';

  private readonly RECOMMENDATION_CLASS_NAME: string = 'uf-reco-tiles-present';

  private dom!: RecoHelperElements;

  private recoViewHistory: RecoViewHistory = new RecoViewHistory();

  public templateOptions = get(window, 'uberflip.recommendationOptions.template_data', null);

  public constructor(dom: RecoHelperElements) {
    this.dom = dom;
  }

  public show = (): void => {
    if (!this.dom.parent) return;
    this.dom.parent.classList.remove(this.HIDDEN_CLASS_NAME);
  };

  public setClass = (): void => {
    if (!this.dom.parent) return;
    this.dom.parent.classList.add(this.RECOMMENDATION_CLASS_NAME);
  };

  public renderNewTiles = (tileElements: HTMLElement[]): void => {
    if (!this.dom.list) return;
    const tileArea = new TileArea(this.dom.list);

    tileElements.forEach((tileElement: HTMLElement) => {
      this.dom.list!.appendChild(tileElement);
      tileArea.initializeTile(tileElement);
      this.bindClickTracking(tileElement);
    });
  };

  public bindClickTracking = (anchorElement: HTMLElement): void =>
    anchorElement.addEventListener('click', this.recoViewHistory.handleClick);
}

export default RecommendationHelper;
