class ScrollToComponent {
  private readonly isNativeSupport: boolean;

  private readonly element: HTMLElement;

  private scrollInterval: ReturnType<typeof setTimeout> | null = null;

  public constructor(element: HTMLElement) {
    this.isNativeSupport = 'scrollBehavior' in element.style;
    this.element = element;
  }

  public left = (leftOffset: number): void => {
    if (this.isNativeSupport) {
      this.element.scrollTo({ behavior: 'smooth', left: leftOffset, top: 0 });
    } else {
      this.smoothScrollToLeft(leftOffset);
    }
  };

  private smoothScrollToLeft = (toLeft: number): void => {
    this.clearInterval();

    this.scrollInterval = setInterval(() => {
      const currentScrollLeft = this.element.scrollLeft;
      let scrollLeft = 0;
      let adjustment = 0;

      if (currentScrollLeft > toLeft) {
        adjustment = currentScrollLeft - 50;
        scrollLeft = adjustment < toLeft ? toLeft : adjustment;
      } else if (currentScrollLeft < toLeft) {
        adjustment = currentScrollLeft + 50;
        scrollLeft = adjustment > toLeft ? toLeft : adjustment;
      } else {
        scrollLeft = toLeft;
      }

      this.element.scrollLeft = scrollLeft;

      if (scrollLeft === toLeft) {
        this.clearInterval();
      }
    }, 30);
  };

  private clearInterval = (): void => {
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval);
      this.scrollInterval = null;
    }
  };
}

export default ScrollToComponent;
