interface ElementRequestFullscreen {
  mozRequestFullScreen(): Promise<void>;
  webkitRequestFullscreen(): Promise<void>;
  msRequestFullscreen(): Promise<void>;
}

interface DocumentFullscreen {
  webkitFullscreenElement: Element;
  mozFullScreenElement: Element;
  msFullscreenElement: Element;
  mozCancelFullScreen(): Promise<void>;
  webkitExitFullscreen(): Promise<void>;
  msExitFullscreen(): Promise<void>;
}

const isBrowserFullscreen = (): boolean => {
  const element = document as Document & DocumentFullscreen;

  return !!(
    element.fullscreenElement ||
    element.webkitFullscreenElement ||
    element.mozFullScreenElement ||
    element.msFullscreenElement
  );
};

export const enterBrowserFullscreenMode = (element: HTMLElement): void => {
  if (isBrowserFullscreen()) return;

  const targetElement = element
    ? (element as HTMLElement & ElementRequestFullscreen)
    : (document.documentElement as HTMLElement & ElementRequestFullscreen);

  if (targetElement.requestFullscreen) {
    targetElement.requestFullscreen();
  } else if (targetElement.mozRequestFullScreen) {
    targetElement.mozRequestFullScreen();
  } else if (targetElement.webkitRequestFullscreen) {
    targetElement.webkitRequestFullscreen();
  } else if (targetElement.msRequestFullscreen) {
    targetElement.msRequestFullscreen();
  }
};

export const exitBrowserFullscreenMode = (): void => {
  // Browsers throw error when you try to exit when not in fullscreen mode:
  if (!isBrowserFullscreen()) return;

  const element = document as Document & DocumentFullscreen;

  if (element.exitFullscreen) {
    element.exitFullscreen();
  } else if (element.mozCancelFullScreen) {
    element.mozCancelFullScreen();
  } else if (element.webkitExitFullscreen) {
    element.webkitExitFullscreen();
  } else if (element.msExitFullscreen) {
    element.msExitFullscreen();
  }
};
