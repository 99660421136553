import { SliderComponent, SliderElements } from './slider';
import get from 'lodash.get';
import hubEvents from '../../../../common/hub_events/hub_events';
import RecommendationController, {
  RECO_STRATEGY_OVERRIDE,
} from '../../../../common/recommendations/recommendation_controller';
import RecommendationHelper from '../../../../common/recommendations/recommendation_helper';
import TileArea from '../../tiles/tile_area';

class RelatedItemsComponent {
  private readonly selectors = {
    control: {
      next: '#uf-slider-next',
      previous: '#uf-slider-previous',
    },
    header: '#uf-slider-title',
    list: '#uf-slider-tiles-list',
    parent: '#uf-slider',
    track: '#uf-slider-track',
  };

  private dom!: SliderElements;

  private recoController: RecommendationController;

  private recoHelper!: RecommendationHelper;

  private tileArea!: TileArea;

  public constructor(recommendationController: RecommendationController) {
    this.recoController = recommendationController;

    if (this.setBindings()) {
      this.recoHelper = new RecommendationHelper({
        list: this.dom.list,
        parent: this.dom.parent,
      });

      this.init();
    }
  }

  private setBindings(): boolean {
    const selector = this.selectors;
    const slider = document.querySelector(selector.parent) as HTMLElement;
    if (!slider) {
      return false;
    }

    const header = slider.querySelector(selector.header) as HTMLElement;
    const previous = slider.querySelector(selector.control.previous) as HTMLButtonElement;
    const next = slider.querySelector(selector.control.next) as HTMLButtonElement;
    const track = slider.querySelector(selector.track) as HTMLElement;
    const list = slider.querySelector(selector.list) as HTMLUListElement;
    if (!header || !previous || !next || !track || !list) {
      return false;
    }

    this.dom = {
      control: { next, previous },
      header,
      list,
      parent: slider,
      track,
    };

    return true;
  }

  private init = async (): Promise<void> => {
    if (this.recoController.getStrategy() === RECO_STRATEGY_OVERRIDE) {
      await this.swapRecommendations();
    } else {
      this.bindTileEvents();
    }

    new SliderComponent(this.dom);
  };

  private bindTileEvents = (): void => {
    this.tileArea = new TileArea(this.dom.list);
    this.tileArea.initializeTiles();
  };

  private swapRecommendations = async (): Promise<void> => {
    const recoResponseBody = await this.recoController.getService().call();

    if (!recoResponseBody || !recoResponseBody.tiles || !recoResponseBody.tiles.length) {
      return;
    }

    this.recoHelper.setClass();
    this.swapRecommendationHeader();
    this.clearRecentArticleTiles();
    this.recoHelper.renderNewTiles(recoResponseBody.tiles as HTMLElement[]);

    hubEvents.publish('recoItemsLoaded');
  };

  private swapRecommendationHeader = (): void => {
    const headerText = get(this.recoHelper.templateOptions, 'header_text', null);
    if (headerText) this.dom.header.innerHTML = headerText;
  };

  // Clear the Most Recent Articles tiles to make room for the Recommendation tiles.
  private clearRecentArticleTiles = (): void => {
    const listElement = this.dom.list;
    const listItemElements = [
      ...(listElement.getElementsByTagName('li') as HTMLCollectionOf<HTMLElement>),
    ];
    listItemElements.forEach((listItemElement) => listElement.removeChild(listItemElement));
  };
}

export default RelatedItemsComponent;
